import { Company, Coach, RiskMessage } from '@modules/types';
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { apiClient, apiGet } from '.';
import { Items } from '@modules/types';
import { openNewPdf } from '@utils/routes';

/*
refetchOnWindowFocus & staleTime configuration ensure auth0 token is still valid everytime user interact with the application
*/
export const DEFAULT_CONTACT_ID = '485ce8ee-04a5-46e8-ba33-3a73699d1332';

export const useCompany = apiGet<Company>('/company', {
  refetchOnWindowFocus: true,
  staleTime: 0,
});

export const useCoach = (): UseQueryResult<Coach | undefined, AxiosError> => {
  const apiCall = async () => {
    const response = await apiClient.get<Items<Coach>>('/coach');
    return response?.data?.items?.length > 0
      ? response?.data?.items[0]
      : undefined;
  };

  return useQuery(['/coach'], apiCall, {
    useErrorBoundary: false,
    retry: 0,
  });
};

export const useSendMessageContact = () =>
  useMutation(({ contactId, ...message }: RiskMessage) =>
    apiClient.post(`/coach/${contactId || DEFAULT_CONTACT_ID}/form`, message)
  );

export const useOpenHazardReportPdf = () =>
  useMutation((companyId: string) =>
    apiClient.get(`/companies/${companyId}/hazard_report_pdf`).then((res) => {
      openNewPdf(res.data.url);
    })
  );
